import React, {useState} from "react";
import {motion, AnimatePresence} from "framer-motion";
import styled, {createGlobalStyle} from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";
import Seo from "./Seo";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    padding: 0;
    margin: 0;
    max-width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    color: white;
    background-color: #398ab9;
  }

  a {
    text-decoration: none;
    background-color: transparent;
    color: white;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-areas: 
    "header"
    "main"
    "footer";
  grid-template-rows: auto 1fr auto;
  min-width: 0;
  max-width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const Main = styled.main`
  grid-area: main;
  padding: 20px;
  min-width: 0;
  max-width: 100%;
  z-index: 1;

  @media (min-width: 1440px) {
    padding: 30px;
  }
`;

const LayoutB = ({pageTitle, children}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <GlobalStyle/>
      <Container>
        <Seo title={pageTitle}/>
        <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
        <AnimatePresence>
          {menuOpen && (
            <motion.div
              style={{
                position: "fixed",
                top: "60px",
                left: "0",
                width: "100%",
                zIndex: 2
              }}
              initial={{y: -300, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -300, opacity: 0}}
              transition={{duration: 0.3}}
            >
              <Menu setMenuOpen={setMenuOpen}/>
            </motion.div>
          )}
        </AnimatePresence>
        <Main>{children}</Main>
        <Footer/>
      </Container>
    </>
  );
};

export default LayoutB;