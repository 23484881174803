import React from "react";
import {Link} from "gatsby";
import {FiArrowLeft} from "react-icons/fi";
import styled from "styled-components";
import LayoutB from "../components/LayoutB";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
`;

const Card = styled.div`
  border-radius: 15px;
  padding: 15px;
  background-color: white;
  color: black;

  @media (min-width: 1280px) {
    padding: 20px;
  }

  @media (min-width: 1440px) {
    padding: 30px;
  }
`;

const Title = styled.div`
  margin-bottom: 25px;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2rem;
`;

const Message = styled.div`
  margin-bottom: 25px;
  font-size: 1rem;
  line-height: 1.4rem;
`;

const LinkCustom = styled(Link)`
  display: flex;
  align-items: center;
  color: black;
  
  & > svg {
    margin-right: 4px;
  }
`;

const SuccessPage = () => {
  return (
    <LayoutB pageTitle="Rio Pacifico">
      <Container>
        <Card>
          <Title>&iexcl;Gracias!</Title>
          <Message>Hemos recibido su mensaje. Un asesor se estará contactando con usted a la brevedad.</Message>
          <LinkCustom to="/contacto">
            <FiArrowLeft/> Volver
          </LinkCustom>
        </Card>
      </Container>
    </LayoutB>
  );
};

export default SuccessPage;